import {
  Button,
  Flex,
  FlexProps,
  Text,
  useColorModeValue,
  Heading,
  Divider,
  Grid,
  GridItem,
  IconButton,
  Avatar
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getUserUnfinishedNegotiations } from '../../api';
import { dashboardViewAtom, userAtom } from '../../atoms';
import { capitalize } from '../../utils/capitalize';
import { Check } from '../icons/Check';
import { Notification } from '../icons/Notification';
import { PlusCircle } from '../icons/PlusCircle';
import { dashboardTheme, loginTheme } from 'src/theme/theme';

export interface ActionRequiredCondensedProps extends FlexProps {}

export const ActionRequiredCondensed: React.FC<
  ActionRequiredCondensedProps
> = ({ ...props }) => {
  const color = useColorModeValue('brand.secondary2', 'brand.secondary1');
  const highlightColor = useColorModeValue('#6B30BA', 'brand.primary');
  const pillBg = useColorModeValue('rgba(4, 5, 24, 0.1)', 'brand.secondary2');
  const bg = useColorModeValue('brand.secondary1', '#1B1C31');
  const createNewBg = useColorModeValue('#DBDBDB', 'brand.secondary2');

  const [user] = useAtom(userAtom);
  const [negotiations, setNegotiations] = useState<any[]>([]);
  const [, setView] = useAtom(dashboardViewAtom);
  const navigate = useNavigate();

  useEffect(() => {
    const getNegotiations = async () => {
      const { data } = await getUserUnfinishedNegotiations(9, 0);
      setNegotiations(data);
    };

    getNegotiations();
  }, []);

  const handleOnCreate = async () => {
    navigate('/pre-step-through');
    // navigate('/process');
  };

  const handleOnClick = async (negotiation: any) => {
    if (negotiation.status === 'created') {
      navigate(
        `/step-through/${negotiation.contractType._id}?negotiation=${negotiation._id}`
      );
    } else if (negotiation.status === 'active-contract') {
      navigate(`/negotiation/${negotiation._id}`);
    } else if (negotiation.contract) {
      navigate(`/negotiation/${negotiation._id}`);
    } else {
      //const { data } = await createContract(negotiation._id);
      navigate(`/negotiation/${negotiation._id}`);
      //navigate(`/contract/${data.contract._id}`);
    }
  };

  return (
    <>
      <Flex gap="36px" alignItems="center">
        <Heading
          fontWeight="400"
          fontSize={{ base: '32px', lg: '40px' }}
          lineHeight="48px"
          color={dashboardTheme.primaryTextColor}
        >
          Your active contracts
        </Heading>
        <Button
          variant="transparent"
          h="32px"
          width="129px"
          padding="0 12px 0 12px"
          borderRadius="6px"
          onClick={() => setView('ActionRequired')}
          borderColor={dashboardTheme.buttonBackgroundColor}
          // rightIcon={<Dropdown h="6.67px" w="10.83px" />}
        >
          View all
        </Button>
      </Flex>
      <Divider
        mt={{ base: '16px', lg: '20px' }}
        borderColor={dashboardTheme.primaryTextColor}
      />

      <Flex py="24px" gap="16px" flexDir={{ base: 'column', lg: 'row' }}>
        <Flex
          backgroundColor={dashboardTheme.receivedContractBoxBackground}
          borderRadius="5px"
          h="293px"
          w={{ base: '100%', lg: '278px' }}
          padding="24px"
          flexDirection="column"
          display={{ base: 'none', lg: 'flex' }}
        >
          <Flex flex="1" alignItems="center" justifyContent="center">
            <IconButton
              aria-label="Create new contract"
              onClick={() => handleOnCreate()}
              icon={
                <PlusCircle
                  color={dashboardTheme.createContractIconColor}
                  h="69.75"
                  w="69.75"
                />
              }
              height="69.75px"
              width="69.75px"
              borderRadius="50%"
              background="none"
              border="none"
            />
          </Flex>
          <Button
            onClick={() => handleOnCreate()}
            color={dashboardTheme.buttonTextColor}
            background={dashboardTheme.buttonBackgroundColor}
          >
            Create new contract
          </Button>
        </Flex>
        <Button display={{ lg: 'none' }} onClick={() => handleOnCreate()}>
          Create new contract
        </Button>
        <Flex
          gap="16px"
          flexDirection={{ base: 'column', lg: 'row' }}
          w="100%"
          overflowX="auto"
        >
          {negotiations.map((negotiation: any) => {
            const isInitiator = negotiation.initiator.email === user?.email;
            let otherParty = isInitiator
              ? negotiation.receiver
              : negotiation.initiator;

            if (otherParty === null || otherParty === undefined) {
              otherParty = {
                firstName: isInitiator
                  ? negotiation.receiverEmail
                  : negotiation.initiatorEmail,
                lastName: '',
                profileImageUrl: ''
              };
            }

            let buttonText = '';

            if (negotiation.numMissingTerms === 0) {
              buttonText = 'View Contract';
            } else if (negotiation.status === 'created') {
              buttonText = 'Create Offer';
            } else {
              buttonText = 'Negotiate';
            }

            return (
              <Flex
                key={negotiation._id}
                background={dashboardTheme.receivedContractBoxBackground}
                borderRadius="5px"
                h={{ lg: '293px' }}
                w={{ base: '100%', lg: '278px' }}
                minW={{ base: '100%', lg: '278px' }}
                padding="16px 18px"
                flexDirection="column"
                justifyContent="space-between"
                gap={{ base: '24px', lg: '0' }}
              >
                <Grid
                  templateColumns={{
                    base: 'repeat(2, 1fr)',
                    lg: 'repeat(1, 1fr)'
                  }}
                >
                  <GridItem>
                    <Text
                      fontSize="16px"
                      fontWeight="400"
                      lineHeight="19.2px"
                      color={color}
                    >
                      {negotiation.contractType.subjectType}: "
                      {negotiation.subject}"
                    </Text>
                    <Text
                      fontSize="12px"
                      fontWeight="700"
                      lineHeight="14.4px"
                      mt="8px"
                      color={color}
                    >
                      Started {new Date(negotiation.createdAt).toDateString()}
                    </Text>
                    <Flex
                      background={dashboardTheme.contractTypePillBackground}
                      h="25px"
                      borderRadius="5px"
                      mt="16px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text
                        fontSize="10px"
                        fontWeight="700"
                        lineHeight="12px"
                        color={dashboardTheme.contractTypePillTextColor}
                      >
                        {negotiation.contractType.name.toUpperCase()}
                      </Text>
                    </Flex>
                  </GridItem>
                  <GridItem>
                    <Flex
                      gap="8px"
                      alignItems={{ lg: 'center' }}
                      justifyContent={{ base: 'flex-end', lg: 'flex-start' }}
                      mt={{ lg: '21px' }}
                    >
                      <Avatar src={otherParty.profileImageUrl} size="sm" />
                      <Flex flexDirection="column">
                        <Text
                          fontSize="12px"
                          fontWeight="400"
                          lineHeight="16px"
                          color={color}
                        >
                          {otherParty.firstName} {otherParty.lastName}
                        </Text>
                        <Text
                          fontSize="10px"
                          fontWeight="700"
                          lineHeight="12px"
                          color={color}
                        >
                          {isInitiator
                            ? capitalize(
                                negotiation.receiverRoleLabel ||
                                  negotiation.receiverRole
                              )
                            : capitalize(
                                negotiation.initiatorRoleLabel ||
                                  negotiation.initiatorRole
                              )}
                        </Text>
                      </Flex>
                    </Flex>
                  </GridItem>
                </Grid>

                <Flex flexDirection="column" gap="8px">
                  {negotiation.numMissingTerms === 0 ? (
                    <Flex gap="8px" alignItems="center">
                      <Check
                        color={dashboardTheme.termsRemainingColor}
                        h="13.88px"
                        w="13.88px"
                      />
                      <Text
                        color={dashboardTheme.termsRemainingColor}
                        fontSize="12px"
                        lineHeight="12px"
                        fontWeight="700"
                        mr="8px"
                      >
                        All approved
                      </Text>
                    </Flex>
                  ) : (
                    <Flex gap="8px" alignItems="center">
                      <Notification
                        color={dashboardTheme.termsRemainingColor}
                        h="13.88px"
                        w="13.88px"
                      />
                      <Text
                        color={dashboardTheme.termsRemainingColor}
                        fontSize="12px"
                        lineHeight="12px"
                        fontWeight="700"
                        mr="8px"
                      >
                        {negotiation.numMissingTerms} term(s) remaining
                      </Text>
                    </Flex>
                  )}

                  <Button
                    variant="transparent"
                    justifySelf="end"
                    color={dashboardTheme.outlineButtonTextColor}
                    onClick={() => handleOnClick(negotiation)}
                  >
                    {buttonText}
                  </Button>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </>
  );
};
