import {
  Heading,
  useColorModeValue,
  FlexProps,
  Flex,
  Text,
  FormControl,
  Input,
  Button
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { passwordResetTheme, verifyEmailTheme } from 'src/theme/theme';

export interface EnterEmailProps extends FlexProps {
  onEmailEntered: (email: string) => void;
}

export const EnterEmail: React.FC<EnterEmailProps> = ({
  onEmailEntered,
  ...props
}) => {
  const color = passwordResetTheme.inputColor;

  const [email, setEmail] = useState<string>('');

  return (
    <Flex p="32px" flexDir="column" alignItems="center" {...props}>
      <Heading
        fontSize="56px"
        fontWeight="400"
        lineHeight="50.4px"
        color={passwordResetTheme.modalHeadingColor}
      >
        Reset Password
      </Heading>
      <Text
        align="center"
        mt="24px"
        fontSize="16px"
        fontWeight="400"
        lineHeight="20px"
        color={passwordResetTheme.modalBodyColor}
      >
        Enter email to recieve instructions for resetting your password
      </Text>
      <FormControl mt="72px" w={{ base: '100%', lg: '70%' }}>
        <Input
          borderColor={color}
          color={color}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={'Enter your email address'}
          _placeholder={{
            color: color
          }}
          _hover={{ borderColor: passwordResetTheme.inputOutlineHoverColor }}
        />
      </FormControl>
      <Button
        mt="24px"
        w="138px"
        h="40px"
        variant="gradient"
        onClick={() => onEmailEntered(email)}
        background={verifyEmailTheme.buttonBackgroundColor}
        color={verifyEmailTheme.buttonTextColor}
      >
        Send code
      </Button>
    </Flex>
  );
};
