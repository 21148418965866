import React, { useEffect } from 'react';
import {
  Flex,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  FlexProps,
  useToast,
  Image,
  FormErrorMessage
} from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAtom } from 'jotai';

import { Footer } from '../components/core/Footer';
import { loginUser } from '../api';
import { redirectAtom, userAtom, navbarColorAtom } from '../atoms';
import { loginTheme } from 'src/theme/theme';

export interface LoginProps extends FlexProps {}

export const Login: React.FC<LoginProps> = ({ ...props }) => {
  const navigate = useNavigate();
  const [navbarColor, setNavbarColor] = useAtom(navbarColorAtom);
  const [user, setUser] = useAtom(userAtom);
  const location = useLocation();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({});
  const [redirect] = useAtom(redirectAtom);

  useEffect(() => {
    if (location.state?.newlyRegisteredUser) {
      toast({
        title: 'Registration successful!',
        description: 'Your account was created. Please log in.',
        status: 'success',
        duration: 50000,
        isClosable: true
      });
    }
  }, [location, toast]);

  useEffect(() => {
    if (user) {
      if (redirect) {
        navigate(redirect);
      } else {
        navigate('/');
      }
    }
  }, [user, navigate, redirect]);

  useEffect(() => {
    setNavbarColor('#1E2029');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: any) => {
    try {
      const response = await loginUser(values);
      setUser(response.data);
    } catch {
      toast({
        title: 'Login unsuccessful',
        description: 'Invalid email or password',
        status: 'error',
        duration: 5000
      });
    }
  };
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      h={{ lg: loginTheme.mainContentFixedHeight }}
      minH={{ base: '100vh', lg: '0' }}
      overflow={{ lg: 'hidden' }}
      {...props}
    >
      <Flex
        flexGrow="1"
        overflow={{ lg: 'hidden' }}
        minH={{ base: '100%', lg: '0' }}
      >
        <Box
          flexGrow="1"
          h="100%"
          background={loginTheme.background}
          bgPosition="center"
          bgSize="cover"
          display={{ base: 'none', lg: 'initial' }}
        >
          <img
            src={`${process.env.REACT_APP_API_URL}/admin/environment-logo`}
            style={{
              maxHeight: '500px',
              display: 'block',
              margin: '0px auto',
              position: 'relative',
              top: '50%',
              transform: 'translateY(-50%)'
            }}
          ></img>
        </Box>
        <Box
          w={{ base: '100%', lg: '620px' }}
          background={loginTheme.background}
          px={{ base: '8px', lg: '43px' }}
          overflowY="auto"
          minH={{ base: '100%', lg: '0' }}
        >
          <Flex
            as="form"
            noValidate
            flexDirection="column"
            h="100%"
            w={{ base: '100%', lg: '332px' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Image
              src="/login.png"
              style={{
                maxWidth: '50vw',
                margin: '0px auto'
              }}
              margin={'0px auto'}
              display={{ base: 'block', lg: 'none' }}
            ></Image>
            <Heading
              fontSize="104px"
              fontWeight="400"
              mt="100px"
              color={loginTheme.primaryTextColor}
            >
              Login
            </Heading>
            <FormControl mt="16px" isInvalid={!!errors.email}>
              <FormLabel color={loginTheme.primaryTextColor}>
                Email address
              </FormLabel>
              <Input
                {...register('email', { required: true })}
                borderColor={loginTheme.inputOutlineColor}
                color={loginTheme.inputTypedTextColor}
                _hover={{ borderColor: loginTheme.inputOutlineHoverColor }}
              />
              <FormErrorMessage>Email is required</FormErrorMessage>
            </FormControl>
            <FormControl mt="16px" isInvalid={!!errors.password}>
              <FormLabel color={loginTheme.primaryTextColor}>
                Password
              </FormLabel>
              <Input
                type="password"
                {...register('password', { required: true })}
                borderColor={loginTheme.inputOutlineColor}
                color={loginTheme.inputTypedTextColor}
                _hover={{ borderColor: loginTheme.inputOutlineHoverColor }}
              />
              <FormErrorMessage>Password is required</FormErrorMessage>
            </FormControl>

            <Link
              to="/password-reset"
              style={{
                color: loginTheme.primaryTextColor,
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '20px',
                marginTop: '8px'
              }}
            >
              Forgot password
            </Link>

            <Flex mt="36px" gap="16px">
              <Button
                minW={{ lg: '138px' }}
                h="40px"
                type="submit"
                flex="1"
                color={loginTheme.buttonTextColor}
                backgroundColor={loginTheme.buttonBackgroundColor}
              >
                Login
              </Button>

              <Button
                minW={{ lg: '178px' }}
                h="40px"
                flex="1"
                onClick={() => navigate('/register')}
                color={loginTheme.outlineButtonTextColor}
                backgroundColor={loginTheme.outlineButtonBackgroundColor}
                borderColor={loginTheme.outlineButtonBorderColor}
                borderWidth="1px"
                borderStyle="solid"
              >
                Create an account
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Flex>
      <Footer />
    </Flex>
  );
};
